import './switch.css';
import {SwitchProps} from "./switch.props";
import React from "react";
import {FormControlLabel, Switch as MuiSwitch} from "@mui/material";
import {ProgressIndicator, ProgressIndicatorType} from "../progress-indicator";

/**
 * Button component
 */
export const Switch = function ButtonComponent(props: SwitchProps) {
    const { labelTx, checked, onChange, waiting, disabled = false } = props

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked);
    };

    return <div className={"switch"}>
        <FormControlLabel label={labelTx}
                          control={
                              <MuiSwitch checked={checked}
                                         onChange={handleChange}
                                         disabled={disabled}
                                         inputProps={{ 'aria-label': 'controlled' }}
                              />
                          }
        />
        {waiting && <ProgressIndicator type={ProgressIndicatorType.Linear}/>}
    </div>
}
