import {useCallback, useState} from 'react'
import useIsMounted from "./useIsMounted";

const DEFAULT_WAITING_STRING = "waiting";

function useServiceApi<T>(api: (params: any) => Promise<T>) {
    const [ waiting, setWaiting ] = useState<string>("");
    const [ error, setError ] = useState<string>();
    const isMounted = useIsMounted()

    const execute = useCallback(async (params?: any, waitingString: string = DEFAULT_WAITING_STRING): Promise<T> => {
        return new Promise<T>(async resolve => {
            setWaiting(waitingString);
            setError("")
            try {
                const result = await api(params);
                if (isMounted()) {
                    resolve(result);
                }
            } catch (e: any) {
                const error = e.message ?? e.toString()
                isMounted() && setError(error);
            } finally {
                isMounted() && setWaiting("");
            }
        });
    }, [api, isMounted]);

    return { execute, waiting, error, setError };
}

export default useServiceApi
