import './header.css';
import {HeaderProps} from "./header.props";
import React from "react";
import {ArrowBackSharp} from "@mui/icons-material";
import {IconButton} from "../icon-button";

/**
 * Header component
 */
export const Header = function HeaderComponent(props: HeaderProps) {
    const { title, onBack, children } = props

    return <div className={"header"}>
        <div className={"header__line"}>
            {
                onBack ?
                    <IconButton icon={ArrowBackSharp} onClick={onBack}/>
                    :
                    <div className={"header__no-back"}/>
            }
            <div className={"header__title"}>
                {title}
            </div>
            {children}
        </div>
        <div className={"content__devider"}/>
    </div>
}
