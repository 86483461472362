
export interface TextFieldProps {

    labelTx: string;

    value: string | number;

    onChange: (newValue: string, finish: boolean) => void;

    type?: TextFieldPropsType;
}

export enum TextFieldPropsType {
    text = "text",
    number = "number",
}
