import './price-threshold-rule.css';
import {Operation} from "@coma/api/rules/dto/operation";
import React, {useState} from "react";
import {PriceThresholdRuleProps} from "./price-threshold-rule.props";
import {
    Button, getCoinOptions,
    getExchangeOptions,
    getOperationOptions,
    Select,
    TextField,
    TextFieldPropsType
} from "../../../../components";
import {Coin, Exchange} from "@coma/api/exchanges";
import {Save} from "@mui/icons-material";

/**
 * Price diff rule
 */
export const PriceThresholdRule = function PriceThresholdRuleComponent(props: PriceThresholdRuleProps) {
    const { rule, isFastEdit, updateRule, waiting } = props
    const [ coin, setCoin ] = useState<Coin>(rule.coin);
    const [ priceThreshold, setPriceThreshold ] = useState<number>(rule.priceThreshold);
    const [ exchange, setExchange ] = useState<Exchange>(rule.exchange);
    const [ operation, setOperation ] = useState<Operation>(rule.operation);
    const updatePriceThresholdButtonRef = React.useRef();
    const updateRuleButtonRef = React.useRef();

    return <div className={"price-threshold-rule"}>
        <Select labelTx={"Coin"}
                value={coin}
                options={getCoinOptions()}
                onChange={(newValue: string) => {
                    if (isFastEdit) {
                        updateRule(
                            {
                                id: rule.id,
                                coin: newValue as Coin,
                            },
                            "update-coin",
                        );
                    } else {
                        setCoin(newValue as Coin)
                    }
                }}
        />
        <Select labelTx={"Exchange price"}
                value={exchange}
                options={getExchangeOptions()}
                onChange={(newValue: string) => {
                    if (isFastEdit) {
                        updateRule(
                            {
                                id: rule.id,
                                exchange: newValue as Exchange,
                            },
                            "update-exchange",
                        );
                    } else {
                        setExchange(newValue as Exchange)
                    }
                }}
        />
        <div className={"content__devider"}/>
        <Select labelTx={"is"}
                value={operation}
                options={getOperationOptions()}
                onChange={(newValue: string) => {
                    if (isFastEdit) {
                        updateRule(
                            {
                                id: rule.id,
                                operation: newValue as Operation,
                            },
                            "update-operation",
                        );
                    } else {
                        setOperation(newValue as Operation)
                    }
                }}
        />
        <div className={"content__devider"}/>
        <div className={"price-threshold-rule__row "}>
            <div className={"price-threshold-rule__field__threshold"}>
                <TextField labelTx={"Price threshold"}
                           type={TextFieldPropsType.number}
                           value={priceThreshold}
                           onChange={(newValue, finish) => {
                               const percent = Number.parseFloat(newValue)
                               setPriceThreshold(percent)
                               if (finish) {
                                   if (isFastEdit) {
                                       // @ts-ignore
                                       updatePriceThresholdButtonRef.current?.focus();
                                   } else {
                                       // @ts-ignore
                                       updateRuleButtonRef.current?.focus();
                                   }
                               }
                           }}
                />
            </div>
            {
                isFastEdit
                && <Button titleTx="Update threshold"
                           forwardedRef={updatePriceThresholdButtonRef}
                           waiting={waiting === "update-threshold"}
                           onClick={() => {
                               updateRule(
                                   {
                                       id: rule.id,
                                       priceThreshold,
                                   },
                                   "update-threshold",
                               );
                           }}
                />
            }
        </div>

        {
            !isFastEdit
            && <div className={"price-threshold-rule__update"}>
                <Button forwardedRef={updateRuleButtonRef}
                        icon={Save}
                        onClick={() => {
                            updateRule(
                                {
                                    id: rule.id,
                                    priceThreshold,
                                    exchange,
                                    operation,
                                },
                                "update-rule",
                            );
                        }}
                />
            </div>
        }
    </div>
}
