import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "white"
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "white"
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "white"
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: "white"
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                track: {
                    backgroundColor: "white"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                startIcon: {
                    margin: "0px 0px",
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    "&.Mui-disabled": {
                        color: "white",
                        opacity: 0.3
                    }
                }
            }
        }
    },
    palette: {
        primary: {
            main: "rgb(139,150,112)"
        },
        secondary: {
            main: "rgb(156,39,176)" // --color-important
        }
    }
});

export default theme;
