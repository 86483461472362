import {AxiosRequestConfig} from "axios";
import {axios_get, axiosSetAllowOrigin, axiosSetAuthToken} from "../../common/services/api.configuration";

export const LOGIN_ROUTE = "/login"

class LoginApi {

    login(userPin: string): Promise<string> {

        axiosSetAllowOrigin();

        const config: AxiosRequestConfig = {
            params: {
                //
            }
        };
        return axios_get(`${LOGIN_ROUTE}/${userPin}`, config, processLoginResult);
    }

    logout(): Promise<void> {
        axiosSetAuthToken("");
        return Promise.resolve();
    }
}

const processLoginResult = (data: any): string => {
    const token = data;
    axiosSetAuthToken(token);
    return token;
}

export const serviceLoginApi = new LoginApi();
