import './text-field.css';
import {TextFieldProps, TextFieldPropsType} from "./text-field.props";
import React from "react";
import {TextField as MuiTextField} from "@mui/material";

/**
 * Text field component
 */
export const TextField = function TextFieldComponent(props: TextFieldProps) {
    const { labelTx, value, onChange, type = TextFieldPropsType.text } = props

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value, false);
    };

    const inputProps = getInputProps(type)

    return <div className={"text-field"}>
        <MuiTextField variant="outlined"
                      label={labelTx}
                      value={value}
                      onChange={onChangeHandler}
                      onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                              event.preventDefault();
                              event.stopPropagation();
                              // @ts-ignore
                              onChange(event.target.value, true);
                          }
                      }}
                      inputProps={inputProps}
                      fullWidth
        />
    </div>
}

const getInputProps = (type: TextFieldPropsType) => {
    switch (type) {
        case TextFieldPropsType.number:
            return {
                step: 1,
                min: 0,
                max: 99999,
                type: 'number',
            }
        case TextFieldPropsType.text:
        default:
            return undefined
    }
}
