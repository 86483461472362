import './icon-button.css';
import {IconButtonProps, IconButtonSize} from "./icon-button.props";
import React from "react";
import {SvgIcon} from '@mui/material';
import {ProgressIndicator} from "../progress-indicator";

/**
 * Icon button component
 */
export const IconButton = function IconButtonComponent(props: IconButtonProps) {
    const { icon, size = IconButtonSize.MEDIUM, waiting, important, warning, noPadding, onClick } = props
    const color = (warning && "warning") || (important && "secondary") || "primary";
    const buttonClass = noPadding ? "icon-button_no-padding" : "icon-button";

    const fontSize =
        (size === IconButtonSize.SMALL && 20)
        || (size === IconButtonSize.MEDIUM && 32)
        || (size === IconButtonSize.LARGE && 48) || 32;

    return <div className={buttonClass}>
        {waiting ?
            <ProgressIndicator/>
            :
            <div className={"icon-button__action"}
                 onClick={onClick}
            >
                <SvgIcon color={color} component={icon} sx={{ fontSize }}/>
            </div>
        }
    </div>
}
