import './error.css';
import { ErrorProps } from "./error.props";
import React from "react";
import {ErrorSharp} from "@mui/icons-material";
import {Button} from "../button/button";
import {IconButton} from "../icon-button";

/**
 * Error component
 */
export const Error = function ButtonComponent(props: ErrorProps) {
    const { errorTx, tryAgainAction, onLogout } = props

    return <div className={"error"}>
        <div className={"error__header"}>
            <IconButton icon={ErrorSharp}/>
            Error
        </div>
        <div className={"error__text"}>
            {errorTx}
        </div>
        <div className={"error__actions"}>
            <Button titleTx={"Try again"} onClick={tryAgainAction}/>
            <Button titleTx={"Logout"} onClick={onLogout}/>
        </div>
    </div>
}
