import './account-details.css';
import React from "react";
import {AccountDetailsProps} from "./account-details.props";
import {LicenseType} from "@coma/api/users";
import {getLicenseType} from "@coma/api/utils/general-utils";
import {EmptyList} from "../../../components";

/**
 * Account details
 */
export const AccountDetails = function AccountDetailsComponent(props: AccountDetailsProps) {
    const { account } = props
    const licenseType = getLicenseType(account);

    return <div className={"account-details"}>
        {
            !account ?
                <EmptyList titleTx={"No account..."}/>
                :
                <>
                    <div className={"account-details__row"}>
                        <span>Name:</span>
                        <span className={"account-details__value"}>
                            {account.name}
                        </span>
                    </div>
                    <div className={"account-details__row"}>
                        <span>Phone:</span>
                        <span className={"account-details__value"}>
                            {account.phone}
                        </span>
                    </div>
                    <div className={"content__devider"}/>
                    {
                        licenseType === LicenseType.FREE ?
                            <div className={"account-details__row"}>
                                <span>Licence type:</span>
                                <span className={"account-details__value"}>
                                    {licenseType}
                                </span>
                            </div>
                            : undefined
                    }
                    {
                        licenseType === LicenseType.STANDARD ?
                            <>
                                <div className={"account-details__row"}>
                                    <span>Remaining SMS(s):</span>
                                    <span className={"account-details__value"}>
                                        {account.licenseStandard.smsCount}
                                    </span>
                                </div>
                                <div className={"account-details__row"}>
                                    <span>Licence type:</span>
                                    <span className={"account-details__value"}>
                                        {licenseType}
                                    </span>
                                </div>
                            </>
                            : undefined
                    }
                    {
                        licenseType === LicenseType.PROFESSIONAL ?
                            <>
                                <div className={"account-details__row"}>
                                    <span>Remaining SMS(s):</span>
                                    <span className={"account-details__value"}>
                                        {account.licenseProfessional.smsCount}
                                    </span>
                                </div>
                                <div className={"account-details__row"}>
                                    <span>Licence type:</span>
                                    <span className={"account-details__value"}>
                                        {licenseType}
                                    </span>
                                </div>
                            </>
                            : undefined
                    }
                </>
        }
        <div className={"content__splitter"}/>
    </div>
}
