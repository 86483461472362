import './select.css';
import {SelectProps} from "./select.props";
import React from "react";
import {Select as MuiSelect, InputLabel, MenuItem, SelectChangeEvent, FormControl} from "@mui/material";

/**
 * Select component
 */
export const Select = function SelectComponent(props: SelectProps) {
    const { labelTx, value, options, onChange } = props

    const labelId = "label_" + labelTx;
    const componentId = "id_" + labelTx;
    const handleChange = (event: SelectChangeEvent) => {
        const value = event.target.value;
        onChange(value);
    };


    return <div className={"select"}>
        <FormControl fullWidth>
            <InputLabel id={labelId}>{labelTx}</InputLabel>
            <MuiSelect labelId={labelId}
                       id={componentId}
                       value={value}
                       label={labelTx}
                       onChange={handleChange}
            >
                {options.map((option) => {
                    return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                })}
            </MuiSelect>
        </FormControl>
    </div>
}

