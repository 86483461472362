import './login.css';
import {LoginProps} from "./login.props";
import React, {useState} from "react";
import {serviceLoginApi} from "@coma/api/login";
import {Button, TextField} from "../../components";

/**
 * Login page
 */
export const Login = function LoginPage(props: LoginProps) {
    const { onLogin } = props;
    const [ userPin, setUserPin ] = useState<string>("")

    const onPinChange = (newValue: string, finish: boolean) => {
        setUserPin(newValue);
        if (finish) {
            loginUser();
        }
    }

    const loginUser = () => {
        if (userPin) {
            serviceLoginApi.login(userPin)
                .then(token => {
                    onLogin(token);
                })
        }
    }

    return <div className={"login"}>
        <div className={"login__circle"}>
        <div className={"login__circle-background"}/>
        <div className={"login__form"}>
                <div className={"login__title"}>
                    Green koda
                </div>
                <TextField labelTx="Login-pin"
                           value={userPin}
                           onChange={onPinChange}
                />
                <Button titleTx="Login" onClick={loginUser}/>
            </div>
        </div>
    </div>
}
