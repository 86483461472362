import './twitter-rule.css';
import React, {useState} from "react";
import {Button, TextField, TextFieldPropsType} from "../../../../components";
import {TwitterRuleProps} from "./twitter-rule.props";
import {Save} from "@mui/icons-material";

/**
 * Twitter rule
 */
export const TwitterRule = function TwitterRuleComponent(props: TwitterRuleProps) {
    const { rule, updateRule, waiting } = props
    const [ inclusiveKeyWords, setInclusiveKeyWords ] = useState<string>(wordsToString(rule.inclusiveKeyWords));
    const [ exclusiveKeyWords, setExclusiveKeyWords ] = useState<string>(wordsToString(rule.exclusiveKeyWords));
    const updateKeyWordsButtonRef = React.useRef();

    return <div className={"twitter-rule"}>
        <TextField labelTx={"Inclusive key words"}
                   type={TextFieldPropsType.text}
                   value={inclusiveKeyWords}
                   onChange={setInclusiveKeyWords}
        />
        <TextField labelTx={"Exclusive key words"}
                   type={TextFieldPropsType.text}
                   value={exclusiveKeyWords}
                   onChange={(newValue, finish) => {
                       setExclusiveKeyWords(newValue);
                       if (finish) {
                           // @ts-ignore
                           updateKeyWordsButtonRef.current?.focus();
                       }
                   }}
        />
        <div className={"twitter-rule__update-key-words"}>
            <Button icon={Save}
                    waiting={waiting === "update-key-words"}
                    forwardedRef={updateKeyWordsButtonRef}
                    onClick={() => {
                        updateRule(
                            {
                                id: rule.id,
                                inclusiveKeyWords: wordsToArray(inclusiveKeyWords),
                                exclusiveKeyWords: wordsToArray(exclusiveKeyWords),
                            },
                            "update-key-words",
                        );
                    }}
            />
        </div>
    </div>
}

const wordsToString = (words: string[]): string => {
    return words.join(", ");
}

const wordsToArray = (wordsAsString: string): string[] => {
    const words = wordsAsString.split(",")
    return words.map(w => w.trim());
}
