import {AxiosRequestConfig} from "axios";
import {axios_delete, axios_get, axios_patch} from "../../common/services/api.configuration";
import {RuleDto} from "../index";

export const RULES_ROUTE = "/rules"

class RulesApi {

    getRules(): Promise<RuleDto[]> {
        const config: AxiosRequestConfig = {};
        return axios_get(RULES_ROUTE, config, mapToRules);
    }

    addRule(newRule: RuleDto): Promise<RuleDto> {
        const config: AxiosRequestConfig = {};
        return axios_patch(RULES_ROUTE, newRule, config, mapToRule);
    }

    updateRule(rule: RuleDto): Promise<RuleDto> {
        const config: AxiosRequestConfig = {};
        return axios_patch(`${RULES_ROUTE}/${rule.id}`, rule, config, mapToRule);
    }

    deleteRule(ruleId: number): Promise<void> {
        return axios_delete(`${RULES_ROUTE}/${ruleId}`);
    }
}

const mapToRule = (data: any): RuleDto => {
    return data;
}

const mapToRules = (data: any): RuleDto[] => {
    return data;
}

export const serviceRulesApi = new RulesApi();
