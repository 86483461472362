import {SvgIconComponent} from "@mui/icons-material";

export interface IconButtonProps {

    // https://mui.com/material-ui/material-icons/
    icon: SvgIconComponent;

    size?: IconButtonSize;

    waiting?: boolean;

    important?: boolean;

    warning?: boolean;

    noPadding?: boolean;

    onClick?: () => void;
}

export enum IconButtonSize {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
}
