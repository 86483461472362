import './progress-indicator.css';
import {ProgressIndicatorProps, ProgressIndicatorType} from "./progress-indicator.props";
import React from "react";
import {Box, CircularProgress, LinearProgress} from "@mui/material";

/**
 * Progress indicator component
 */
export const ProgressIndicator = function ButtonComponent(props: ProgressIndicatorProps) {
    const { type = ProgressIndicatorType.Circular } = props

    return <div className={"progress-indicator"}>
        {type === ProgressIndicatorType.Circular && <CircularProgress/>}
        {type === ProgressIndicatorType.Linear && <Box sx={{ width: '100%' }}><LinearProgress /></Box>}
    </div>
}
