import {AxiosRequestConfig} from "axios";
import {axios_get, axios_patch} from "../../common/services/api.configuration";
import {UserDto} from "../dto";
import {LicenseType} from "../dto/license.type";
import {LicenseDto} from "../dto/license.dto";
import {SETTINGS_ROUTE, SettingsDto} from "../../settings";

export const USERS_ROUTE = "/users"
export const LICENSE_ROUTE = "/licenses"

class UserApi {

    getAllUsers(): Promise<UserDto[]> {
        const config: AxiosRequestConfig = {};
        return axios_get(`${USERS_ROUTE}/all`, config);
    }

    getUser(): Promise<UserDto> {
        const config: AxiosRequestConfig = {};
        return axios_get(USERS_ROUTE, config);
    }

    updateUser(user: Partial<UserDto>): Promise<UserDto> {
        const config: AxiosRequestConfig = {};
        return axios_patch(USERS_ROUTE, user, config);
    }

    orderLicense(licenseType: LicenseType, smsCount: number): Promise<string> {
        const config: AxiosRequestConfig = {};
        return axios_get(`${LICENSE_ROUTE}/${licenseType}/${smsCount}`, config);
    }

    purchaseLicense(uuid: string, smsCount: number): Promise<UserDto> {
        const config: AxiosRequestConfig = {};
        const license: Partial<LicenseDto> = {
            uuid,
            smsCount,
        }
        return axios_patch(`${LICENSE_ROUTE}`, license, config);
    }
}

export const serviceUserApi = new UserApi();
