import React from "react";
import {ButtonProps} from "./button.props";
import './button.css';
import {Button as MuiButton, SvgIcon} from "@mui/material";
import {ProgressIndicator} from "../progress-indicator";

/**
 * Button component
 */
export const Button = function ButtonComponent(props: ButtonProps) {
    const { onClick, titleTx, icon, waiting, disabled, highlight, blink, noPadding, forwardedRef } = props
    const startIcon = icon ? <SvgIcon component={icon}/> : undefined;
    const blinkClass = blink ? "blink" : undefined;
    const buttonClass = noPadding ? "button_no-padding" : "button";

    return <div className={buttonClass}>
        {waiting ?
            <ProgressIndicator/>
            :
            <>
                {
                    highlight
                    && <div className={blinkClass}>
                        <div className={"button__highlighted_top"}/>
                    </div>
                }
                <MuiButton ref={forwardedRef}
                           onClick={onClick}
                           variant="contained"
                           color={"primary"}
                           startIcon={startIcon}
                           disabled={disabled}
                    // sx={ { borderRadius: 28 } }
                >
                    {titleTx}
                </MuiButton>
                {
                    highlight
                    && <div className={blinkClass}>
                        <div className={"button__highlighted_bottom"}/>
                    </div>
                }
            </>
        }
    </div>
}
