import {AxiosRequestConfig} from "axios";
import {axios_delete, axios_get} from "../../common/services/api.configuration";
import {NotificationDto} from "../dto/notification.dto";

export const NOTIFICATIONS_ROUTE = "/notifications"

class NotificationsApi {

    getNotifications(): Promise<NotificationDto[]> {
        const config: AxiosRequestConfig = {};
        return axios_get(NOTIFICATIONS_ROUTE, config, mapToNotificationDto);
    }

    deleteNotifications(): Promise<void> {
        return axios_delete(NOTIFICATIONS_ROUTE);
    }
}

const mapToNotificationDto = (data: any): NotificationDto[] => {
    return data;
}

export const serviceNotificationsApi = new NotificationsApi();
