import * as DOMPurify from "dompurify";

// https://github.com/cure53/DOMPurify/blob/main/demos/hooks-target-blank-demo.html
DOMPurify.addHook("afterSanitizeAttributes", (node: any) => {
    // set all elements owning target to target=_blank
    if ("target" in node) {
        node.setAttribute("target", "_blank");
        // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
        node.setAttribute("rel", "noopener noreferrer");
    }
});

export function sanitizeText(text: string) {
    return DOMPurify.sanitize(text);
}

const protocols = ["https:", "http:"];
export function validateURL(url: string): string {
    try {
        const parsedUrl = new URL(url);
        return protocols.includes(parsedUrl.protocol) ? url : "";
    } catch (e) {
        // tslint:disable-next-line:no-console
        console.log(`Failed to validate URL: ${url}`);
        return "";
    }

}
