import './empty-list.css';
import { EmptyListProps } from "./empty-list.props";
import React from "react";

/**
 * Empty list component
 */
export const EmptyList = function EmptyListComponent(props: EmptyListProps) {
    const { titleTx } = props

    return <div className={"empty-list"}>
        {titleTx}
    </div>
}
