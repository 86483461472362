
export interface ProgressIndicatorProps {

    type?: ProgressIndicatorType;
}

export enum ProgressIndicatorType {
    Circular,
    Linear,
}
